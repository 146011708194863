import React, { useRef } from "react";
import Layout from "components/Layout";
import { Container } from "ducks/Search/styles";
import { navigate } from "gatsby";
import { HTMLContainer } from "../../ducks/Search/styles";

export default function DocumentContentPage({ location }) {
  const htmlRef = useRef();
  let _html = location?.state?.fields?.description ?? "";

  return (
    <Layout title="search results">
      <Container>
        <div className="go-back" onClick={() => navigate(-1)}>
          {"< GO BACK"}
        </div>
        <HTMLContainer
          ref={htmlRef}
          dangerouslySetInnerHTML={{ __html: _html }}
        />
      </Container>
    </Layout>
  );
}
