import styled, { css } from "styled-components";
import { Link } from "gatsby";
import { media } from "components/Theme/default-theme";
import Icon from "components/Icon";

export const SearchImage = styled.img`
  display: none;
  height: 100%;
  width: auto;
  aspect-ratio: 1.4;
  object-fit: cover;
  background: #fff;
  margin-right: 0.5em;
  ${({ isLoaded }) =>
    !isLoaded
      ? css`
          filter: blur(5px) brightness(75%);
        `
      : ""}
`;

export const ArrowRightIcon = styled(Icon)`
  transform: scaleX(-1);
  position: absolute;
  right: 0.5em;
  bottom: 0;
  top: 0;
  margin: auto 0;
  height: 28px;
  width: 28px;
  svg {
    max-height: 100%;
    max-width: 100%;
  }
`;

export const TextContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 1em 2.5em 0em 1em;
  background: #f2f2f2;
  overflow: hidden;
  justify-content: stretch;
  gap: 10px;
`;

export const BlurMask = styled.div`
  right: 0;
  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  background: #f2f2f2;
  mask-image: linear-gradient(to bottom, transparent 65%, black 100%);
`;

export const InternalLinkCard = styled(Link)`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0.25em 0;
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;
  height: 8em;
  background: #fff;

  div.html-container > * {
    font-size: 0.8em;
  }
`;

export const ExternalLinkCard = styled.a`
  width: 100%;
  margin: 0.25em 0;
  padding: 1em 3em 1em 1em;
  background: #f2f2f2;
  cursor: pointer;
  text-decoration: none;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 2.5em;
`;

export const SectionBreak = styled.div`
  width: 100%;
  border: 0.5px solid #696057;
  height: 0px;
  margin-bottom: 1em;
`;

export const HTMLContainer = styled.div`
  padding: 3.5em 0;
  font-family: Gotham, sans-serif !important;
  color: #382e2b !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  > * {
    font-family: Gotham, sans-serif !important;
    color: #382e2b !important;
  }

  a {
    position: relative;
  }

  a.disabled {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
  }

  img {
    position: relative;
  }

  img:hover,
  a:hover {
    color: #ff6000 !important;
  }
`;

export const Container = styled.div`
  width: 90%;
  margin: auto;
  box-sizing: content-box;

  ${media.tablet} {
    ${SearchImage} {
      display: block;
    }
  }

  ${media.laptop} {
    width: 50%;
  }

  * {
    font-family: Gotham, sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #696057;
    text-align: left;
  }

  .card-url {
    font-size: 0.6em;
    font-size: clamp(0.59375rem, 0.5063rem + 0.2914vw, 0.8125rem);
    margin: 0;
    color: #ff5f00;
    display: flex;
    height: fit-content;
    white-space: nowrap;
    overflow: hidden;
  }

  .card-title {
    font-size: 0.8em;
    margin: 0;
    font-size: clamp(0.75rem, 0.5502rem + 0.6661vw, 1.25rem);
    height: fit-content;
    display: flex;
  }

  .card-description {
    font-size: 0.6em;
    font-size: clamp(0.59375rem, 0.5063rem + 0.2914vw, 0.8125rem);
    height: fit-content;
    margin: 0;
    display: flex;
    overflow: hidden;
  }

  .view-more {
    background: transparent;
    border: none;
    cursor: pointer;
    align-self: flex-end;
    width: fit-content;
    text-align: right;
    font-size: 12px;
    margin: 3em 0;
  }

  .go-back {
    text-align: left;
    cursor: pointer;
    font-size: 12px;
    margin: 2em 0 0;
  }
`;
